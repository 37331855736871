import { keyframes, useToast } from '@chakra-ui/react';
import { useQ } from "@olagg/api-hooks";
import { RouletteEndpoints, fetcher } from '@olagg/api-hooks';
import { RouletteValue } from "@olagg/db-types";
import { useState, useContext, createContext } from "react";
import { sleep } from '../../../../utils/sleep';
import { analytics } from '../../../../analytics';
import { useLocation } from 'react-router-dom'
import { AdTrackingContext } from '../../../../contexts/adTrackingContext';

const RouletteContext = createContext({
  values: [],
  count: 0,
  animation: '',
  spinRoulette: (callback?: (result: RouletteValue) => void) => { },
  result: null,
  isSpinning: false,
});

export default function useRoulette() {
  return useContext(RouletteContext);
}

export function RouletteProvider({ children }) {
  const { fcb, fbp } = useContext(AdTrackingContext)
  const [animation, setAnimation] = useState<string>('');
  const [result, setResult] = useState<RouletteValue>(null);
  const [isSpinning, setIsSpinning] = useState<boolean>(false);
  const toast = useToast();
  const { pathname } = useLocation()
  const pathnameExtract = pathname.split(`/`)[2]

  const { data } = useQ<{ rouletteValues: RouletteValue[], meta: { count: number } }>(RouletteEndpoints.all());

  const spinRoulette = (callback?: (result: RouletteValue) => void) => {
    let props = {
      owner: pathnameExtract,
      user_agent: navigator.userAgent,
    }
    if (fcb != null) props.fbc = fcb
    if (fbp != null) props.fbp = fbp
    analytics()?.track('Roulette Spin Started', props);
    setIsSpinning(true);
    fetcher(RouletteEndpoints.spin())
      .then(result => {
        const degreesToTurn = (360 * 7) - 36 * result.position;
        const animationKeyframes = keyframes`
          0%   {transform: rotate(0deg)}
          15%  {transform: rotate(-20deg)}
          100% {transform: rotate(${degreesToTurn}deg)}`;
        setAnimation(`${animationKeyframes} 5s ease forwards`);
        sleep(6000)
          .then(() => {
            setResult(result);
            callback && callback(result);
            setIsSpinning(false);
            let props = {
              xp: result.value,
              owner: pathnameExtract,
              user_agent: navigator.userAgent,
            }
            if (fcb != null) props.fbc = fcb
            if (fbp != null) props.fbp = fbp
            analytics()?.track('Roulette Spin Completed', props);
          });
      })
      .catch(error => {
        setIsSpinning(false)
        toast({
          title: 'Error',
          colorScheme: 'olaggYellow',
          status: 'error',
          description: error.error,
          position: 'bottom-right',
          isClosable: true
        })
        console.log(error);
      });
  }

  return (
    <RouletteContext.Provider
      value={{
        values: data?.rouletteValues?.map(val => val.value),
        count: data?.meta?.count,
        animation,
        spinRoulette,
        result,
        isSpinning,
      }}
    >
      {children}
    </RouletteContext.Provider>
  )
}
