import { AUTH_METHODS, STEPS } from "../constants";
import { Button, Flex, GridItem, Image, InputGroup, InputRightElement, Link, SimpleGrid, Text, Tooltip, useBreakpointValue, useToast } from "@chakra-ui/react";

import Modal from "../../../components/Modal/Modal";
import OlaGGFormInput from "../../../components/Form/OlaggFormInput";
import OlaggForm from "../../../components/Form/OlaggForm";
import { ROUTES } from "../../../routes";
import { analytics } from "../../../analytics";
import useAuth from "../hooks/useAuth";
import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { useOnboardingStore } from "@olagg/store";
import { useTranslation } from "react-i18next";

type IProps = {
  email: string,
  changeEmail: (email: string) => void
}

function IconFor({ method }: { method: string }) {
  const IconComponent = AUTH_METHODS[method].icon;

  return <IconComponent />
}

const ChooseMethod = ({ changeEmail }: IProps) => {
  const { t } = useTranslation();
  const { isOnboardingOpen, step, setStep, closeOnboarding, forceDiscord } = useOnboardingStore()
  const { loginWithProvider, handleUserToken, autoFill, abortController } = useAuth()
  const toast = useToast()
  const useFormMethods = useForm({
    mode: 'onChange',
    shouldUseNativeValidation: false,
  })

  const navigate = useNavigate();
  const { pathname } = useLocation()
  const pathnameExtract = pathname.split(`/`)[2]

  const handleViewPolicy = (path: string) => () => {
    closeOnboarding();
    navigate(`${ROUTES.POSTS}${path}`);
  }

  const handleLogInWithProvider = (provider: string) => () => loginWithProvider(provider)

  const handleSubmit = async (formData) => {
    changeEmail(formData.email);
    setStep(STEPS.verificationCode);
  }

  const clearEmail = () => {
    useFormMethods.setValue('email', '')
    changeEmail('')
  }

  const enablePasskeyAutofill = () =>
    autoFill()
      .then((resp) => {
        changeEmail(resp.user.email)
        if (!resp.user.verified) {
          setStep(STEPS.verificationCode)
        } else if (forceDiscord) {
          setStep(STEPS.linkDiscord)
        } else {
          handleUserToken(resp, 'passkey')
          closeOnboarding()
        }
      })
      .catch((err) => {
        if (err.error === 'no_matching_credential_found') {
          toast({
            title: t('Login.enablePasskeyAutofill.invalidPassKeyTitle'),
            description: t('Login.enablePasskeyAutofill.invalidPassKeyDescription'),
            status: 'error',
            duration: 9000,
            isClosable: true,

          })
          let props = {
            reason: "invalid passkey",
            owner: pathnameExtract,
            user_agent: navigator.userAgent
          }

          analytics()?.track("User SignIn Failed", props)
          enablePasskeyAutofill()
        }
      })

  useEffect(() => {
    if (isOnboardingOpen) enablePasskeyAutofill()
    return () => abortController.abort();
  }, [isOnboardingOpen])

  const isMobile = useBreakpointValue(
    {
      base: true,
      md: false,
    },
    {
      fallback: 'md',
    },
  )

  return (
    <Modal isOpen={isOnboardingOpen && step === STEPS.chooseMethod} onClose={closeOnboarding} size="xl" color="white" isMobile={isMobile ? true : false} colorScheme='#0C0324'>
      <SimpleGrid gap={6} columns={{ base: 1, md: 2 }} bg="#0C0324" color="white" px={0} py={2} borderRadius="base">
        <GridItem>
          <Flex justifyContent="center" alignItems="center" h="100%" direction={'column'} my={isMobile ? 2 : 6} px='6' textAlign={'center'}>
            <Text fontSize={{ base: "md", md: "xl" }}>{t('Login.welcome')}</Text>
            <Image src='/logo-ola.svg' w={{ md: '100%', sm: '100%' }} />
            <Text fontSize={{ base: "md", md: "xl" }} fontWeight={500}>
            {t('Login.welcomeLead')}
            </Text>
          </Flex>
        </GridItem>
        <GridItem>
          <Flex h="100%" direction={'column'} my={2} px='6' mt={6} borderLeft={{
            base: 'none',
            md: '1px solid #3D3D3D'
          }} fontSize='md'>
            <Text>{t('Login.socialNetworkAccess')}</Text>
            <Flex gap='3' mt='5' direction={'column'}>
              {Object.keys(AUTH_METHODS).map((method, index) => (
                AUTH_METHODS[method].active && (
                  // <Tooltip hasArrow label={`Ingresar con ${method}`} bg='white' color='black' placement='top'>
                  <Button
                    fontSize={{ base: "xs", md: "lg" }}
                    leftIcon={<IconFor method={method} />}
                    w={'100%'}
                    key={index}
                    variant="outline"
                    onClick={handleLogInWithProvider(method)}
                    _hover={{
                      background: 'linear-gradient(90deg, #4E10FF 0%, #AB17DF 100%)'
                    }}
                  >
                    {t(`Login.continueWith.${AUTH_METHODS[method].text}`)}
                  </Button>
                  // </Tooltip>
                )
              ))}
            </Flex>
            <Text mt="6">{t('Login.emailAccess')}</Text>
            <OlaggForm
              useFormMethods={useFormMethods}
              onSubmit={handleSubmit}
            >
              <InputGroup size='md'>
                <OlaGGFormInput
                  type="email"
                  required={true}
                  controlName="email"
                  placeholder={t('Login.emailPlaceholder')}
                  autoComplete="username webauthn"
                />
                <InputRightElement >
                  <Button size='sm' onClick={clearEmail} variant="unstyled" mt="4" color="gray.400">
                    x
                  </Button>
                </InputRightElement>
              </InputGroup>
              <Button
                type='submit'
                width={'100%'}
                variant={'filledGradient'}
                fontSize={{ base: "sm", md: "md" }}
                disabled={!useFormMethods.formState.isValid}
              >
                {t('Login.continueWithEmail')}
              </Button>
            </OlaggForm>
          </Flex>
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 2 }}>
          <Flex justifyContent='center' px='25px' py={2} color='white' fontSize={{ base: "xs", md: "sm" }}>
            <Text >
            {t('Login.acceptingInContinue')}
              <Link onClick={handleViewPolicy('/tyc')} mx='1'>{t('Login.tyc')} </Link> {t('Login.andThe')}
              <Link onClick={handleViewPolicy('/privacidad')} mx='1'>{t('Login.privacyPolicy')} </Link> {t('Login.ofOla')}
            </Text>
          </Flex>

        </GridItem>
      </SimpleGrid >
    </Modal >
  )
}

export default ChooseMethod;
