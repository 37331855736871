import Modal from "../../../components/Modal/Modal";
import { Box, Flex, FormControl, FormErrorMessage, HStack, Input, Link, PinInput, PinInputField, Text } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";
import { STEPS } from "../constants";
import { useOnboardingStore } from "@olagg/store";
import { analytics } from '../../../analytics'
import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from 'react-router-dom'

type IProps = {
  email: string,
  verificationCode?: string
}

const resendCode = (countDown, sendCode) => {
  const { t } = useTranslation();
  if (countDown < 60) {

    return (
      <Flex justifyContent='center' mt='4'>
        <Text fontSize='16px' lineHeight='24px' fontWeight='400' textAlign={'center'}>
          {t('Login.VerificationCode.resendCodeIn')}
        </Text>
        <Text fontWeight={'bold'} ml='1'>{60 - countDown} {t('Login.VerificationCode.sec')}</Text>
      </Flex>
    )
  } else {
    return (
      <Link color="#3F2091" onClick={sendCode}>{t('Login.VerificationCode.resendCode')}</Link>
    )
  }
}

const VerificationCode = ({ email, verificationCode }: IProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()
  const pathnameExtract = (pathname.split(`/`)[1] == 'login') || pathname.split(`/`)[2] == 'login' ? searchParams.get('partner') : pathname.split(`/`)[2]
  const [countDown, setCountDown] = useState(0);
  const { sendAuthCode, validateAuthCode, handleUserToken } = useAuth()
  const [isInvalid, setIsInvalid] = useState(false)

  const { setStep, isOnboardingOpen, closeOnboarding } = useOnboardingStore()

  const sendCode = async () => {
    await sendAuthCode(email)
    setCountDown(0)
  }

  const handleSubmit = async (code: string) => {
    try {
      const response = await validateAuthCode(email, code)
      const user = await handleUserToken(response, 'email_magic_link')
      let props = {
        email,
        owner: pathnameExtract,
        user_agent: navigator.userAgent
      }
      
      analytics()
        ?.track('User Magic Link Opened', props)

      if (user?.webauthn) {
        setStep(STEPS.completeData)
      } else {
        setStep(STEPS.setPasskey)
      }
    } catch (e) {
      console.log("ERROR", e)
      let props = {
        reason: "invalid temporary code",
        owner: pathnameExtract,
        user_agent: navigator.userAgent
      }
      
      analytics()?.track("User SignIn Failed", props)
      setIsInvalid(true)
    }
  }

  const editEmail = () => {
    setStep(STEPS.chooseMethod)
  }


  const count = async () => {
    setCountDown(prev => prev + 1)
    const promise = new Promise((resolve) => {
      setTimeout(() => {
        resolve(1)
      }, 1000);
    })
    promise.then(() => count())
  }


  useEffect(() => {
    if (verificationCode) {
      handleSubmit(verificationCode)
    } else {
      sendCode()
    }
    count()
    if (email !== '') {
      let props = {
        email,
        identity_provider: 'email_magic_link',
        owner: pathnameExtract,
        user_agent: navigator.userAgent
      }
      
      analytics()
        ?.track('User Email Login Started', props)
    }
  }, [verificationCode])


  return (
    <Modal isOpen={isOnboardingOpen} onClose={closeOnboarding}>
      <Box p="8">
        <Text fontSize='26px' fontWeight='600' mb={4} color="#3F2091">{t('Login.VerificationCode.enterVerificationCode')}</Text>
        <Text fontSize='16px' lineHeight='24px' fontWeight='400'>
          {t('Login.VerificationCode.enterVerificationCodeLead')}
        </Text>

        <Flex mt="8" gap={'4'} justifyContent={'space-between'} alignItems={'center'}>
          <Text fontSize='16px' lineHeight='24px' fontWeight='600'>{email}</Text>
          <Link
            fontSize='16px'
            lineHeight='24px'
            fontWeight='600'
            color="#3F2091"
            onClick={editEmail}
          >
            {t('Login.VerificationCode.edit')}
          </Link>
        </Flex>

        <HStack width={'100%'} justifyContent={'space-between'} mt='4' >
          <PinInput
            isInvalid={isInvalid}
            onComplete={handleSubmit}
            placeholder=''
            size='lg'
            value={verificationCode}
            colorScheme='purple'
            focusBorderColor={isInvalid ? 'red.500' : 'purple.500'}
            errorBorderColor='red.500'
            type='number'
          >
            {[...Array(6)].map((_, index) => (
              <PinInputField key={index} onChange={() => setIsInvalid(false)} />
            ))}

          </PinInput>
        </HStack>
        <FormControl isInvalid={isInvalid}>
          <FormErrorMessage>
            {t('Login.VerificationCode.notValidCode')}
          </FormErrorMessage>
        </FormControl>
        <Text fontSize='16px' lineHeight='24px' fontWeight='400' mt='4' textAlign={'center'}>
          {resendCode(countDown, sendCode)}
        </Text>
      </Box>

    </Modal>
  )
}

export default VerificationCode;
